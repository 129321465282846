import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import barchartfill from "@iconify/icons-eva/bar-chart-fill";
import { project_name } from "src/config/config";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SettingsIcon from "@mui/icons-material/Settings";
import { Icon } from "@iconify/react";
import { ListItemIcon } from "@mui/material";
import personFill from "@iconify/icons-eva/person-fill";
import {
  DashboardIcons,
  RecordingIcons,
  GoalStatmentIcons,
  PodsIcons,
  ProfileIcons,
  Missionontrol,
  MembersIcon,
  AssessmentsStudy,
  YourRecordings,
  YourVault,
  PaymentsIcon,
  CalendarIcon,
  SupportIcon,
  HelpSection,
  TheCosmos,
  DayPlanIcon,
  PagesLinks,
} from "src/assets";
import { useEffect } from "react";
import { _get_Consultant_Nav_from_localStorage } from "src/DAL/localstorage/LocalStorage";
import CircleIcon from "@mui/icons-material/Circle";
import { useContentSetting } from "src/Hooks/ContentSettingState";

// ----------------------------------------------------------------------
const getMUIIcon = (name) => {
  return <ListItemIcon className="navbar-icon">{name}</ListItemIcon>;
};
const NavData = _get_Consultant_Nav_from_localStorage();

const getImageIcon = (name) => (
  <img src={name} width={28} height={28} className="navbar-icon" />
);

const getEvaIcon = (name) => (
  <Icon className="navbar-icon" icon={name} width={28} height={28} />
);

const handldeMenuOption = (navItem, value) => {
  // console.log(navItem, "navvvvvvvssssss");
  let nav_obj = navItem?.find((o) => o?.option_value === value);
  if (nav_obj) {
    return nav_obj;
  } else {
    return "";
  }
};
// useEffect(() => {}, []);

let sidebarConfig = ({
  navItem,
  getNavbarContent,
  recording_name = "",
  type,
}) => {
  // console.log(navItem, "navItemnavItemnavItem");
  // const { userInfo, getNavbarContent } = useContentSetting();

  let navValue;
  let sidebarConfig = [
    {
      title: getNavbarContent({
        option_value: "dashboard",
        option_label: "Dashboard",
      }),
      path: "/dashboard",
      icon: getImageIcon(Missionontrol),
      name: "dashboard",
    },
  ];

  navValue = handldeMenuOption(navItem, "courses");
  if (navValue) {
    sidebarConfig.push({
      title: getNavbarContent(navValue),
      path: "/courses",
      icon: getImageIcon(PaymentsIcon),
      name: navValue?.option_value,
      matches: [
        "/courses",
        "/course-community-dashboard",
        "/course-community-settings",
        "/course-feeddetail",
      ],
    });
  }
  navValue = handldeMenuOption(navItem, "training_programmes");
  if (navValue) {
    sidebarConfig.push({
      title: getNavbarContent(navValue),
      path: "/training-programmes",
      icon: getImageIcon(PaymentsIcon),
      name: navValue?.option_value,
    });
  }

  navValue = handldeMenuOption(navItem, "manage_programmes");
  if (navValue) {
    let caledarChildNav = [];
    const is_community =
      location.pathname.includes("programme-community-dashboard") ||
      location.pathname.includes("lesson-community-dashboard") ||
      location.pathname.includes("programme-sections") ||
      location.pathname.includes("lesson-sections") ||
      location.pathname.includes("programme-community-settings") ||
      location.pathname.includes("lesson-community-settings") ||
      location.pathname.includes("programme-feeddetail") ||
      location.pathname.includes("lesson-feeddetail");
    let calendarChild;

    calendarChild = handldeMenuOption(navItem, "programmes");
    if (calendarChild) {
      caledarChildNav.push({
        title: getNavbarContent(calendarChild),
        name: calendarChild?.option_value,
        path: "/programmes",
        icon: getImageIcon(YourRecordings),
      });
    }

    calendarChild = handldeMenuOption(navItem, "drip_sequence");
    if (calendarChild) {
      caledarChildNav.push({
        title: getNavbarContent(calendarChild),
        name: calendarChild?.option_value,
        path: "/drip",
        icon: getImageIcon(YourRecordings),
      });
    }
    calendarChild = handldeMenuOption(navItem, "recordings");
    if (calendarChild) {
      caledarChildNav.push({
        title: getNavbarContent(calendarChild),
        name: calendarChild?.option_value,
        path: "/recordings",
        icon: getImageIcon(YourRecordings),
      });
    }
    calendarChild = handldeMenuOption(navItem, "recording_categories");
    if (calendarChild) {
      caledarChildNav.push({
        title: getNavbarContent(calendarChild),
        name: calendarChild?.option_value,
        path: "/recording-categories",
        icon: getImageIcon(YourRecordings),
      });
    }
    calendarChild = handldeMenuOption(navItem, "automate_navbar");
    if (calendarChild) {
      caledarChildNav.push({
        title: getNavbarContent(calendarChild),
        name: calendarChild?.option_value,
        path: "/programmes_automate_navbar",
        icon: getImageIcon(CalendarIcon),
      });
    }
    calendarChild = handldeMenuOption(navItem, "configuration");
    if (calendarChild) {
      caledarChildNav.push({
        title: getNavbarContent(calendarChild),
        name: calendarChild?.option_value,
        path: "/programme-configuration",
        icon: getImageIcon(CalendarIcon),
      });
    }
    calendarChild = handldeMenuOption(navItem, "content_onfiguration");
    if (calendarChild) {
      caledarChildNav.push({
        title: getNavbarContent(calendarChild),
        name: calendarChild?.option_value,
        path: "/programme-content-configuration",
        icon: getImageIcon(CalendarIcon),
      });
    }

    sidebarConfig.push({
      title: getNavbarContent(navValue),
      name: navValue?.option_value,
      icon: getImageIcon(YourRecordings),
      child_options: is_community ? [] : caledarChildNav,
      paths: [
        "/lessons",
        "/programme-community-dashboard",
        "/lesson-community-dashboard",
        "/programme-sections",
        "/lesson-sections",
        "/programme-community-settings",
        "/lesson-community-settings",
        "/programme-feeddetail",
        "/lesson-feeddetail",
      ],
      fallback_url: "/programmes",
    });
  }
  navValue = handldeMenuOption(navItem, "website");
  if (navValue) {
    let caledarChildNav = [];
    let calendarChild;
    calendarChild = handldeMenuOption(navItem, "website_pages");
    if (calendarChild) {
      caledarChildNav.push({
        title: getNavbarContent(calendarChild),
        name: calendarChild?.option_value,
        path: "/website-pages",
        icon: getImageIcon(CalendarIcon),
      });
    }

    calendarChild = handldeMenuOption(navItem, "payment_plan");
    if (calendarChild) {
      caledarChildNav.push({
        title: getNavbarContent(calendarChild),
        name: calendarChild?.option_value,
        path: "/payment-plans",
        icon: getImageIcon(CalendarIcon),
      });
    }
    calendarChild = handldeMenuOption(navItem, "page_automation");
    if (calendarChild) {
      caledarChildNav.push({
        title: getNavbarContent(calendarChild),
        name: calendarChild?.option_value,
        path: "/pages-automation",
        icon: getImageIcon(CalendarIcon),
      });
    }
    calendarChild = handldeMenuOption(navItem, "taxes");
    if (calendarChild) {
      caledarChildNav.push({
        title: getNavbarContent(calendarChild),
        name: calendarChild?.option_value,
        path: "/taxes",
        icon: getImageIcon(CalendarIcon),
      });
    }
    // calendarChild = handldeMenuOption(navItem, "domain_setting");
    // if (calendarChild) {
    //   caledarChildNav.push({
    //     title: calendarChild?.option_label,
    //     name: calendarChild?.option_value,
    //     path: "/domain-setting",
    //     icon: getImageIcon(CalendarIcon),
    //   });
    // }
    // calendarChild = handldeMenuOption(navItem, "active_campaign_setting");
    // if (calendarChild) {
    //   caledarChildNav.push({
    //     title: calendarChild?.option_label,
    //     name: calendarChild?.option_value,
    //     path: "/active-campaign-settings",
    //     icon: getImageIcon(CalendarIcon),
    //   });
    // }
    // calendarChild = handldeMenuOption(navItem, "mailchimp_setting");
    // if (calendarChild) {
    //   caledarChildNav.push({
    //     title: calendarChild?.option_label,
    //     name: calendarChild?.option_value,
    //     path: "/mailchimp-setting",
    //     icon: getImageIcon(CalendarIcon),
    //   });
    // }
    // calendarChild = handldeMenuOption(navItem, "stripe");
    // if (calendarChild) {
    //   caledarChildNav.push({
    //     title: calendarChild?.option_label,
    //     name: calendarChild?.option_value,
    //     path: "/stripe-setting",
    //     icon: getImageIcon(CalendarIcon),
    //   });
    // }
    // calendarChild = handldeMenuOption(navItem, "paypal");
    // if (calendarChild) {
    //   caledarChildNav.push({
    //     title: calendarChild?.option_label,
    //     name: calendarChild?.option_value,
    //     path: "/paypal-setting",
    //     icon: getImageIcon(CalendarIcon),
    //   });
    // }
    // calendarChild = handldeMenuOption(navItem, "zoom_setting");
    // if (calendarChild) {
    //   caledarChildNav.push({
    //     title: calendarChild?.option_label,
    //     name: calendarChild?.option_value,
    //     path: "/zoom-setting",
    //     icon: getImageIcon(CalendarIcon),
    //   });
    // }
    // calendarChild = handldeMenuOption(navItem, "cookies_setting");
    // if (calendarChild) {
    //   caledarChildNav.push({
    //     title: calendarChild?.option_label,
    //     name: calendarChild?.option_value,
    //     path: "/cookie-setting",
    //     icon: getImageIcon(CalendarIcon),
    //   });
    // }
    // calendarChild = handldeMenuOption(navItem, "seo_setting");
    // if (calendarChild) {
    //   caledarChildNav.push({
    //     title: calendarChild?.option_label,
    //     name: calendarChild?.option_value,
    //     path: "/seo-setting",
    //     icon: getImageIcon(CalendarIcon),
    //   });
    // }
    calendarChild = handldeMenuOption(navItem, "page_settings");
    if (calendarChild) {
      caledarChildNav.push({
        title: getNavbarContent({
          option_value: "domain_setting",
          option_label: "Domain Setting",
        }),
        // title: `${defaultContent?.website_pages?.website_pages_listing?.side_navs?.domain_setting}`,
        name: "domain_setting",
        path: "/domain-setting",
        icon: getImageIcon(CalendarIcon),
      });
      caledarChildNav.push({
        title: getNavbarContent({
          option_value: "active_campaign_setting",
          option_label: "Active Campaign Setting",
        }),
        // title: `${defaultContent?.website_pages?.website_pages_listing?.side_navs?.active_camaign_setting}`,
        name: "active_campaign_setting",
        path: "/active-campaign-settings",
        icon: getImageIcon(CalendarIcon),
      });
      caledarChildNav.push({
        title: getNavbarContent({
          option_value: "mailchimp_setting",
          option_label: "Mailchimp Setting",
        }),
        // title: `${defaultContent?.website_pages?.website_pages_listing?.side_navs?.mailchimp_setting}`,
        name: "mailchimp-setting",
        path: "/mailchimp-setting",
        icon: getImageIcon(CalendarIcon),
      });

      calendarChild = handldeMenuOption(navItem, "stripe");
      if (calendarChild) {
        caledarChildNav.push({
          title: getNavbarContent(calendarChild),
          // title: `${defaultContent?.website_pages?.website_pages_listing?.side_navs?.stripe_setting}`,
          name: "stripe",
          path: "/stripe-setting",
          icon: getImageIcon(CalendarIcon),
        });
      }
      calendarChild = handldeMenuOption(navItem, "paypal");
      if (calendarChild) {
        caledarChildNav.push({
          title: getNavbarContent(calendarChild),
          // title: `${defaultContent?.website_pages?.website_pages_listing?.side_navs?.paypal_setting}`,
          name: "paypal",
          path: "/paypal-setting",
          icon: getImageIcon(CalendarIcon),
        });
      }

      caledarChildNav.push({
        title: getNavbarContent({
          option_value: "zoom_setting",
          option_label: "Zoom Setting",
        }),
        // title: `${defaultContent?.website_pages?.website_pages_listing?.side_navs?.zoom_setting}`,
        name: "zoom_setting",
        path: "/zoom-setting",
        icon: getImageIcon(CalendarIcon),
      });
      caledarChildNav.push({
        title: getNavbarContent({
          option_value: "cookies_setting",
          option_label: "Cookies Setting",
        }),
        // title: `${defaultContent?.website_pages?.website_pages_listing?.side_navs?.cookies_setting}`,
        name: "cookie_setting",
        path: "/cookie-setting",
        icon: getImageIcon(CalendarIcon),
      });
      caledarChildNav.push({
        title: getNavbarContent({
          option_value: "seo_setting",
          option_label: "SEO Setting",
        }),
        // title: `${defaultContent?.website_pages?.website_pages_listing?.side_navs?.seo_setting}`,
        name: "seo_setting",
        path: "/seo-setting",
        icon: getImageIcon(CalendarIcon),
      });
    }
    sidebarConfig.push({
      title: getNavbarContent(navValue),
      name: navValue?.option_value,
      icon: getImageIcon(CalendarIcon),
      child_options: caledarChildNav,
    });
  }

  // navValue = handldeMenuOption(navItem, "website_pages");
  // if (navValue) {
  //   sidebarConfig.push({
  //     title: navValue?.option_label,
  //     name: navValue?.option_value,
  //     path: "/website-pages",
  //     icon: getImageIcon(PaymentsIcon),
  //   });
  // }
  // navValue = handldeMenuOption(navItem, "payment_plan");
  // if (navValue) {
  //   sidebarConfig.push({
  //     title: navValue?.option_label,
  //     path: "/payment-plans",
  //     icon: getImageIcon(MembersIcon),
  //     name: navValue?.option_value,
  //   });
  // }
  navValue = handldeMenuOption(navItem, "community");
  if (navValue) {
    sidebarConfig.push({
      title: getNavbarContent(navValue),
      path: "/community-dashboard",
      matches: [
        "/community-dashboard",
        "/series-video",
        "feed-detail",
        "/community-settings",
        "/sections",
      ],
      // paths: ["/community-settings"],
      icon: getImageIcon(TheCosmos),
      name: navValue?.option_value,
    });
  }
  // if (location.pathname.includes("/programmes/programmes-detail/")) {
  //   let caledarChildNav = [];
  //   caledarChildNav.push({
  //     title: "hello",
  //     path: "/acjasl",
  //     icon: getImageIcon(YourRecordings),
  //   });
  //   sidebarConfig.push({
  //     title: "Programme Details",
  //     icon: getImageIcon(YourRecordings),
  //     child_options: caledarChildNav,
  //   });
  // }

  navValue = handldeMenuOption(navItem, "members");
  if (navValue) {
    sidebarConfig.push({
      title: getNavbarContent(navValue),
      path: "/member",
      icon: getImageIcon(MembersIcon),
      name: navValue?.option_value,
    });
  }

  navValue = handldeMenuOption(navItem, "settings");
  // console.log(navValue, "navValue-setting");
  if (navValue) {
    let paymentChild = [];
    let childNav;
    childNav = handldeMenuOption(navItem, "coach_setting");
    if (childNav) {
      paymentChild.push({
        title: getNavbarContent(childNav),
        name: childNav?.option_value,
        path: "/default-setting",
        icon: getImageIcon(PaymentsIcon),
      });
    }
    childNav = handldeMenuOption(navItem, "email_configuration");
    if (childNav) {
      paymentChild.push({
        title: getNavbarContent(childNav),
        name: childNav?.option_value,
        path: "/email-setting",
        icon: getImageIcon(PaymentsIcon),
      });
    }
    childNav = handldeMenuOption(navItem, "email_templates");
    if (childNav) {
      paymentChild.push({
        title: getNavbarContent(childNav),
        name: childNav?.option_value,
        path: "/email-templates",
        icon: getImageIcon(PaymentsIcon),
      });
    }

    // childNav = handldeMenuOption(navItem, "manage_domains");
    // if (childNav) {
    //   paymentChild.push({
    //     title: childNav?.option_label,
    //     name: childNav?.option_value,
    //     path: "/manage-domains",
    //     icon: getImageIcon(PaymentsIcon),
    //   });
    // }

    // childNav = handldeMenuOption(navItem, "stripe");
    // if (childNav) {
    //   paymentChild.push({
    //     title: childNav?.option_label,
    //     name: childNav?.option_value,
    //     path: "/stripe",
    //     icon: getImageIcon(PaymentsIcon),
    //   });
    // }
    // childNav = handldeMenuOption(navItem, "active_campaign_settings");
    // if (childNav) {
    //   paymentChild.push({
    //     title: childNav?.option_label,
    //     name: childNav?.option_value,
    //     path: "/active-campaign",
    //     icon: getImageIcon(PaymentsIcon),
    //   });
    // }

    childNav = handldeMenuOption(navItem, "invoice_setting");
    if (childNav) {
      paymentChild.push({
        title: getNavbarContent(childNav),
        name: childNav?.option_value,
        path: "/invoice-setting",
        icon: getImageIcon(PaymentsIcon),
      });
    }
    // childNav = handldeMenuOption(navItem, "page_settings");
    // if (childNav) {
    //   paymentChild.push({
    //     title: "Stripe",
    //     name: "stripe",
    //     path: "/stripe",
    //     icon: getImageIcon(PaymentsIcon),
    //   });
    // }
    childNav = handldeMenuOption(navItem, "client_portal_brand_setting");
    if (childNav) {
      paymentChild.push({
        title: getNavbarContent(childNav),
        name: childNav?.option_value,
        path: "/general-setting",
        icon: getImageIcon(PaymentsIcon),
      });
    }
    // childNav = handldeMenuOption(navItem, "client_dashboard");
    // if (childNav) {
    //   paymentChild.push({
    //     title: childNav?.option_label,
    //     path: "/client-dashboard",
    //     icon: getImageIcon(PaymentsIcon),
    //   });
    // }
    childNav = handldeMenuOption(navItem, "client_portal_content_setting");
    if (childNav) {
      paymentChild.push({
        title: getNavbarContent(childNav),
        name: childNav?.option_value,
        path: "/content-setting",
        icon: getImageIcon(PaymentsIcon),
      });
    }
    sidebarConfig.push({
      title: getNavbarContent(navValue),
      icon: getImageIcon(PaymentsIcon),
      child_options: paymentChild,
      name: navValue?.option_value,
      hidden: true,
    });

    childNav = handldeMenuOption(navItem, "client_dummy_content");
    if (childNav) {
      paymentChild.push({
        title: getNavbarContent({
          option_value: "client_portal_content",
          option_label: "Client Portal Content",
        }),
        // title: `${defaultContent?.website_pages?.website_pages_listing?.side_navs?.client_portal_content}`,
        name: childNav?.option_value,
        path: "/client-portal-content",
        icon: getImageIcon(PaymentsIcon),
      });
    }

    childNav = handldeMenuOption(navItem, "coach_dummy_content");
    if (childNav) {
      paymentChild.push({
        title: getNavbarContent({
          option_value: "client_default_content",
          option_label: "Client Default Content",
        }),

        // title: `${defaultContent?.website_pages?.website_pages_listing?.side_navs?.client_default_content}`,
        name: childNav?.option_value,
        path: "/client-default-content",
        icon: getImageIcon(PaymentsIcon),
      });
    }

    // childNav = handldeMenuOption(navItem, "client_dashboard_layout");
    if (childNav) {
      paymentChild.push({
        title: getNavbarContent({
          option_value: "client_dashboard_layout",
          option_label: "Client Dashboard Layout",
        }),
        // title: `${defaultContent?.website_pages?.website_pages_listing?.side_navs?.client_portal_content}`,
        name: childNav?.option_value,
        path: "/client-dashboard-layout",
        icon: getImageIcon(PaymentsIcon),
      });
    }

    childNav = handldeMenuOption(navItem, "coach_dummy_content");
    if (childNav) {
      paymentChild.push({
        title: getNavbarContent({
          option_value: "coach_portal_content",
          option_label: "Coach Portal Content",
        }),
        // title: `${defaultContent?.website_pages?.website_pages_listing?.side_navs?.coach_portal_content}`,
        name: childNav?.option_value,
        path: "/coach-portal-content",
        icon: getImageIcon(PaymentsIcon),
      });
    }

    // childNav = handldeMenuOption(navItem, "domain_setting");
    // if (childNav) {
    //   paymentChild.push({
    //     title: childNav?.option_label,
    //     name: childNav?.option_value,
    //     path: "/domain-settings",
    //     icon: getImageIcon(CalendarIcon),
    //   });
    // }
    // childNav = handldeMenuOption(navItem, "active_campaign_setting");
    // if (childNav) {
    //   paymentChild.push({
    //     title: childNav?.option_label,
    //     name: childNav?.option_value,
    //     path: "/active-campaigns",
    //     icon: getImageIcon(CalendarIcon),
    //   });
    // }
    // childNav = handldeMenuOption(navItem, "mailchimp_setting");
    // if (childNav) {
    //   paymentChild.push({
    //     title: childNav?.option_label,
    //     name: childNav?.option_value,
    //     path: "/mailchimp-settings",
    //     icon: getImageIcon(CalendarIcon),
    //   });
    // }
    // childNav = handldeMenuOption(navItem, "stripe");
    // if (childNav) {
    //   paymentChild.push({
    //     title: childNav?.option_label,
    //     name: childNav?.option_value,
    //     path: "/stripe-settings",
    //     icon: getImageIcon(CalendarIcon),
    //   });
    // }
    // childNav = handldeMenuOption(navItem, "paypal");
    // if (childNav) {
    //   paymentChild.push({
    //     title: childNav?.option_label,
    //     name: childNav?.option_value,
    //     path: "/paypal-settings",
    //     icon: getImageIcon(CalendarIcon),
    //   });
    // }
    // childNav = handldeMenuOption(navItem, "zoom_setting");
    // if (childNav) {
    //   paymentChild.push({
    //     title: childNav?.option_label,
    //     name: childNav?.option_value,
    //     path: "/zoom-settings",
    //     icon: getImageIcon(CalendarIcon),
    //   });
    // }
    // childNav = handldeMenuOption(navItem, "cookies_setting");
    // if (childNav) {
    //   paymentChild.push({
    //     title: childNav?.option_label,
    //     name: childNav?.option_value,
    //     path: "/cookies-settings",
    //     icon: getImageIcon(CalendarIcon),
    //   });
    // }
    // childNav = handldeMenuOption(navItem, "seo_setting");
    // if (childNav) {
    //   paymentChild.push({
    //     title: childNav?.option_label,
    //     name: childNav?.option_value,
    //     path: "/seo-setting",
    //     icon: getImageIcon(CalendarIcon),
    //   });
    // }
    childNav = handldeMenuOption(navItem, "page_settings");
    if (childNav) {
      paymentChild.push({
        title: getNavbarContent({
          option_value: "domain_setting",
          option_label: "Domain Setting",
        }),
        // title: `${defaultContent?.website_pages?.website_pages_listing?.side_navs?.domain_setting}`,
        name: "domain_setting",
        path: "/domain-settings",
        icon: getImageIcon(CalendarIcon),
      });
      paymentChild.push({
        title: getNavbarContent({
          option_value: "active_campaign_setting",
          option_label: "Active Campaign Setting",
        }),
        // title: `${defaultContent?.website_pages?.website_pages_listing?.side_navs?.active_camaign_setting}`,
        name: "active_campaign_setting",
        path: "/active-campaigns",
        icon: getImageIcon(CalendarIcon),
      });
      paymentChild.push({
        title: getNavbarContent({
          option_value: "mailchimp_setting",
          option_label: "Mailchimp Setting",
        }),
        // title: `${defaultContent?.website_pages?.website_pages_listing?.side_navs?.mailchimp_setting}`,
        name: "mailchimp-setting",
        path: "/mailchimp-settings",
        icon: getImageIcon(CalendarIcon),
      });

      let childCheck = handldeMenuOption(navItem, "stripe");
      if (childCheck) {
        paymentChild.push({
          title: getNavbarContent(childCheck),
          // title: `${defaultContent?.website_pages?.website_pages_listing?.side_navs?.stripe_setting}`,
          name: "stripe",
          path: "/stripe-settings",
          icon: getImageIcon(CalendarIcon),
        });
      }
      childCheck = handldeMenuOption(navItem, "paypal");
      if (childCheck) {
        paymentChild.push({
          title: getNavbarContent(childCheck),
          // title: `${defaultContent?.website_pages?.website_pages_listing?.side_navs?.paypal_setting}`,
          name: "paypal",
          path: "/paypal-settings",
          icon: getImageIcon(CalendarIcon),
        });
      }

      paymentChild.push({
        title: getNavbarContent({
          option_value: "zoom_setting",
          option_label: "Zoom Setting",
        }),
        // title: `${defaultContent?.website_pages?.website_pages_listing?.side_navs?.zoom_setting}`,
        name: "zoom_setting",
        path: "/zoom-settings",
        icon: getImageIcon(CalendarIcon),
      });
      paymentChild.push({
        title: getNavbarContent({
          option_value: "cookies_setting",
          option_label: "Cookies Setting",
        }),
        // title: `${defaultContent?.website_pages?.website_pages_listing?.side_navs?.cookies_setting}`,
        name: "cookie_setting",
        path: "/cookies-settings",
        icon: getImageIcon(CalendarIcon),
      });
      paymentChild.push({
        title: getNavbarContent({
          option_value: "seo_setting",
          option_label: "SEO Setting",
        }),
        // title: `${defaultContent?.website_pages?.website_pages_listing?.side_navs?.seo_setting}`,
        name: "cookie_setting",
        path: "/seo-setting",
        icon: getImageIcon(CalendarIcon),
      });
    }
    sidebarConfig.push({
      title: getNavbarContent(navValue),
      icon: getImageIcon(PaymentsIcon),
      child_options: paymentChild,
      name: navValue?.option_value,
      hidden: true,
    });
  }

  navValue = handldeMenuOption(navItem, "help");
  if (navValue) {
    let paymentChild = [];
    let childNav;

    childNav = handldeMenuOption(navItem, "support_ticket");
    if (childNav) {
      paymentChild.push({
        title: getNavbarContent(childNav),
        name: childNav?.option_value,
        path: "/support-ticket",
        icon: getImageIcon(PaymentsIcon),
      });
    }
    childNav = handldeMenuOption(navItem, "support_team");
    if (childNav) {
      paymentChild.push({
        title: getNavbarContent(childNav),
        name: childNav?.option_value,
        path: "/support-team",
        icon: getImageIcon(PaymentsIcon),
      });
    }
    childNav = handldeMenuOption(navItem, "department");
    if (childNav) {
      paymentChild.push({
        title: getNavbarContent(childNav),
        name: childNav?.option_value,
        path: "/departments",
        icon: getImageIcon(PaymentsIcon),
      });
    }

    childNav = handldeMenuOption(navItem, "auto_responded_messages");
    if (childNav) {
      paymentChild.push({
        title: getNavbarContent(childNav),
        name: childNav?.option_value,
        path: "/auto-responded-message",
        icon: getImageIcon(PaymentsIcon),
      });
    }
    // childNav = handldeMenuOption(navItem, "coach_support_ticket");
    // if (childNav) {
    //   paymentChild.push({
    //     title: childNav?.option_label,
    //     name: childNav?.option_value,
    //     path: "/support-ticket-coach",
    //     icon: getImageIcon(PaymentsIcon),
    //   });
    // }
    sidebarConfig.push({
      title: getNavbarContent(navValue),
      name: navValue?.option_value,
      icon: getImageIcon(PaymentsIcon),
      child_options: paymentChild,
      hidden: true,
    });
  }

  navValue = handldeMenuOption(navItem, "members");
  if (navValue) {
    // sidebarConfig.push({
    //   title: navValue?.option_label,
    //   path: "/members",
    //   icon: getImageIcon(MembersIcon),
    // });
  }

  navValue = handldeMenuOption(navItem, "payments");
  // navValue = handldeMenuOption(navItem, "website_pages");
  if (navValue) {
    sidebarConfig.push({
      title: getNavbarContent(navValue),
      path: "/transaction-list",
      icon: getImageIcon(PaymentsIcon),
      name: navValue?.option_value,
    });
    // let paymentChild = [];
    // let childNav;
    // childNav = handldeMenuOption(navItem, "transactions");
    // if (childNav) {
    //   paymentChild.push({
    //     title: childNav?.option_label,
    //     path: "/transaction-list",
    //     icon: getImageIcon(PaymentsIcon),
    //   });
    // }

    // sidebarConfig.push({
    //   title: navValue?.option_label,
    //   icon: getImageIcon(PaymentsIcon),
    //   child_options: paymentChild,
    // });
  }

  navValue = handldeMenuOption(navItem, "portal");
  if (navValue) {
    sidebarConfig.push({
      title: getNavbarContent(navValue),
      path: "/portals",
      icon: getImageIcon(MembersIcon),
      name: navValue?.option_value,
      matches: [
        "//portals",
        "/portal-community-dashboard",
        "/portal-sections",
        "/portal-community-settings",
      ],
    });
  }

  navValue = handldeMenuOption(navItem, "calendar");
  if (navValue) {
    let caledarChildNav = [];
    let calendarChild;
    calendarChild = handldeMenuOption(navItem, "calendar_events");
    if (calendarChild) {
      caledarChildNav.push({
        title: getNavbarContent(calendarChild),
        name: calendarChild?.option_value,
        path: "/calendar",
        icon: getImageIcon(CalendarIcon),
      });
    }
    calendarChild = handldeMenuOption(navItem, "pods");
    if (calendarChild) {
      caledarChildNav.push({
        title: getNavbarContent(calendarChild),
        name: calendarChild?.option_value,
        path: "/pods",
        icon: getImageIcon(CalendarIcon),
      });
    }
    calendarChild = handldeMenuOption(navItem, "groups");
    if (calendarChild) {
      caledarChildNav.push({
        title: getNavbarContent(calendarChild),
        name: calendarChild?.option_value,
        path: "/groups",
        icon: getImageIcon(CalendarIcon),
      });
    }
    sidebarConfig.push({
      title: getNavbarContent(navValue),
      name: navValue?.option_value,
      icon: getImageIcon(CalendarIcon),
      child_options: caledarChildNav,
    });
  }

  // navValue = handldeMenuOption(navItem, "pods");
  // if (navValue) {
  //   let caledarChildNav = [];
  //   let calendarChild;
  //   calendarChild = handldeMenuOption(navItem, "pod_events");
  //   if (calendarChild) {
  //     caledarChildNav.push({
  //       title: calendarChild?.option_label,
  //       name: calendarChild?.option_value,
  //       path: "/pods",
  //       icon: getImageIcon(CalendarIcon),
  //     });
  //   }
  //   calendarChild = handldeMenuOption(navItem, "pod_groups");
  //   if (calendarChild) {
  //     caledarChildNav.push({
  //       title: calendarChild?.option_label,
  //       name: calendarChild?.option_value,
  //       path: "/pod-groups",
  //       icon: getImageIcon(CalendarIcon),
  //     });
  //   }
  //   sidebarConfig.push({
  //     title: navValue?.option_label,
  //     name: navValue?.option_value,
  //     icon: getImageIcon(CalendarIcon),
  //     child_options: caledarChildNav,
  //   });
  // }

  navValue = handldeMenuOption(navItem, "automation");
  if (navValue) {
    let caledarChildNav = [];
    let calendarChild;
    calendarChild = handldeMenuOption(navItem, "automate_navbar");
    if (calendarChild) {
      caledarChildNav.push({
        title: getNavbarContent(calendarChild),
        name: calendarChild?.option_value,
        path: "/automate_navbar",
        icon: getImageIcon(CalendarIcon),
      });
    }

    calendarChild = handldeMenuOption(navItem, "page_automation");
    if (calendarChild) {
      caledarChildNav.push({
        title: getNavbarContent(calendarChild),
        name: calendarChild?.option_value,
        path: "/automation",
        icon: getImageIcon(CalendarIcon),
      });
    }
    sidebarConfig.push({
      title: getNavbarContent(navValue),
      name: navValue?.option_value,
      icon: getImageIcon(CalendarIcon),
      child_options: caledarChildNav,
    });
  }

  // navValue = handldeMenuOption(navItem, "chat");
  // if (navValue) {
  //   sidebarConfig.push({
  //     title: navValue?.option_label,
  //     path: "/chat",
  //     icon: getImageIcon(SupportIcon),
  //   });
  // }

  // navValue = handldeMenuOption(navItem, "delegate_pods");
  // if (navValue) {
  //   sidebarConfig.push({
  //     title: navValue?.option_label,
  //     path: "/pods",
  //     icon: getImageIcon(PodsIcons),
  //   });
  // }

  // navValue = handldeMenuOption(navItem, "dynamite_pods");
  // if (navValue) {
  //   sidebarConfig.push({
  //     title: navValue?.option_label,
  //     path: "/dynamite-pods",
  //     icon: getImageIcon(PodsIcons),
  //   });
  // }

  // if (type !== "consultant") {

  // navValue = handldeMenuOption(navItem, "contact_support");
  // if (navValue) {
  //   sidebarConfig.push({
  //     title: navValue?.option_label,
  //     path: "/contact-support",
  //     icon: getImageIcon(SupportIcon),
  //   });
  // }

  // navValue = handldeMenuOption(navItem, "the_cosmos");
  // if (navValue) {
  //   sidebarConfig.splice(1, 0, {
  //     title: navValue?.option_label,
  //     path: "/community",
  //     matches: ["/community", "/series-video", "feed-detail"],
  //     icon: getImageIcon(TheCosmos),
  //   });
  // }

  // sidebarConfig.unshift({
  //   title: "The Cosmos",
  //   path: "/community",
  //   matches: ["/community", "/series-video", "feed-detail"],
  //   icon: getImageIcon(TheCosmos),
  // });

  navValue = handldeMenuOption(navItem, "auto_responder_messages");
  if (navValue) {
    sidebarConfig.push({
      title: getNavbarContent(navValue),
      path: "/auto-responded-message",
      icon: getImageIcon(DayPlanIcon),
    });
  }

  let analyticsChildNav = [];

  analyticsChildNav.push({
    title: "Overview",
    name: "analytics_overview",
    path: "/analytics-overview",
    icon: getImageIcon(YourRecordings),
  });
  analyticsChildNav.push({
    title: "Realtime Report",
    name: "analytics_realtime_report",
    path: "/analytics-realtime-report",
    icon: getImageIcon(YourRecordings),
  });
  analyticsChildNav.push({
    title: "Events",
    name: "analytics_event",
    path: "/analytics-events",
    icon: getImageIcon(YourRecordings),
    nav_item: [
      "/analytics-events",
      "/analytics-events/event-detail/:event_name",
    ],
  });
  analyticsChildNav.push({
    title: "Demographic Report",
    name: "analytics_demographic_report",
    path: "/analytics-demographic-report",
    icon: getImageIcon(YourRecordings),
  });
  analyticsChildNav.push({
    title: "Platform Details",
    name: "analytics_platform_details",
    path: "/analytics-platform-details",
    icon: getImageIcon(YourRecordings),
  });

  sidebarConfig.push({
    title: "Analytics",
    name: "Analytics",
    icon: getImageIcon(PaymentsIcon),
    child_options: analyticsChildNav,
    paths: [
      "/analytics-overview",
      "/analytics-realtime-report",
      "/analytics-events",
      "/analytics-demographic-report",
      "/analytics-platform-details",
      "/analytics-events/event-detail/:event_name",
    ],
    fallback_url: "/analytics-overview",
  });

  // console.log(sidebarConfig, "asvihivajpvajvopas");
  return sidebarConfig;
};

export default sidebarConfig;
